import Vue from 'vue';
import VueRouter from 'vue-router';
/*Router Modules*/
import cpttRouter from '@/router/modules/cpttRouter';
import deptRouter from '@/router/modules/deptRouter';
import stffRouter from '@/router/modules/stffRouter';
import roleRouter from '@/router/modules/roleRouter';
import permRouter from '@/router/modules/permRouter';
import userRouter from '@/router/modules/userRouter';
import apprRouter from '@/router/modules/apprRouter';
import persRouter from '@/router/modules/persRouter';
import leavRouter from '@/router/modules/leavRouter';
import dpvtRouter from '@/router/modules/dpvtRouter';
import optnRouter from '@/router/modules/optnRouter';
import dequRouter from '@/router/modules/dequRouter';
import custRouter from '@/router/modules/custRouter';
import desiRouter from '@/router/modules/desiRouter';
import requRouter from '@/router/modules/requRouter';
import suppRouter from '@/router/modules/suppRouter';
import fequRouter from '@/router/modules/fequRouter';
import qtsoRouter from '@/router/modules/qtsoRouter';
import qutbRouter from '@/router/modules/qutbRouter';
import settRouter from '@/router/modules/settRouter';
import qtucRouter from '@/router/modules/qtucRouter';
import instRouter from '@/router/modules/instRouter';
import mtrbRouter from '@/router/modules/mtrbRouter';
import rqutRouter from '@/router/modules/rqutRouter';
import prodRouter from '@/router/modules/prodRouter';
import sconRouter from '@/router/modules/sconRouter';
import clauRouter from '@/router/modules/clauRouter';
import PodrRouter from '@/router/modules/podrRouter';
import ModrRouter from '@/router/modules/modrRouter';
import spinRouter from '@/router/modules/spinRouter';
import tranRouter from '@/router/modules/tranRouter';
import deliRouter from '@/router/modules/deliRouter';
import pinvRouter from '@/router/modules/pinvRouter';
import puinRouter from '@/router/modules/puinRouter';
import mbinRouter from '@/router/modules/mbinRouter';
import podcRouter from '@/router/modules/podcRouter';
import modcRouter from '@/router/modules/modcRouter';
import ppayRouter from '@/router/modules/ppayRouter';
import fpayRouter from '@/router/modules/fpayRouter';
import tinvRouter from '@/router/modules/tinvRouter';
import segiRouter from '@/router/modules/segiRouter';
import hegiRouter from '@/router/modules/hegiRouter';
import sginRouter from '@/router/modules/sginRouter';
import hginRouter from '@/router/modules/hginRouter';
import sollRouter from '@/router/modules/sollRouter';
import hollRouter from '@/router/modules/hollRouter';
import statRouter from '@/router/modules/statRouter';
import oinvRouter from '@/router/modules/oinvRouter';
import reimRouter from '@/router/modules/reimRouter';
import apayRouter from '@/router/modules/apayRouter';
import adsoRouter from '@/router/modules/adsoRouter';
import stisRouter from '@/router/modules/stisRouter';
import suctRouter from '@/router/modules/suctRouter';
import catpRouter from '@/router/modules/catpRouter';
import exorRouter from '@/router/modules/exorRouter';
import usquRouter from '@/router/modules/usquRouter';
import fcssRouter from '@/router/modules/fcssRouter';
import actualRouter from '@/router/modules/actualRouter';
import pinvchangeRouter from '@/router/modules/pinvChangeRouter';
import packRouter from '@/router/modules/packRouter';
import inquiryRouter from '@/router/modules/inquiry';
import consultRouter from '@/router/modules/consultRouter';
import priceConsultRouter from '@/router/modules/priceConsult';
import informRouter from '@/router/modules/informRouter';
import contractRouter from '@/router/modules/contractRouter';
import mouldRouter from '@/router/modules/mouldRouter';
import requisitionRouter from '@/router/modules/requisitionRouter';
import macoRouter from '@/router/modules/macoRouter';
import sconOrderRouter from '@/router/modules/sconOrderRouter';
import quotationRouter from '@/router/modules/quotationRouter';
import inventorysRouter from '@/router/modules/inventorysRouter';
import prodControlRouter from '@/router/modules/prodControlRouter';
import deliProdRouter from '@/router/modules/deliProdRouter';
import inspectionRouter from '@/router/modules/inspectionRouter';
import applicationRouter from '@/router/modules/applicationRouter';
import inspectionMtrbRouter from '@/router/modules/inspectionMtrbRouter';
import applicationMtrbRouter from '@/router/modules/applicationMtrbRouter';
import otherStatisticsRouter from '@/router/modules/otherStatisticsRouter';
import DTdeliRouter from '@/router/modules/DTdeliRouter';
import InveRouter from '@/router/modules/InveRouter';
import PushRouter from '@/router/modules/pushRouter';
import fequSampleRouter from '@/router/modules/fequSampleRouter';
import domeSconRequisitionRouter from '@/router/modules/domeSconRequisitionRouter';
import qcInspectionRouter from '@/router/modules/qcInspectionRouter';
import ProductDesignGroupRouter from '@/router/modules/productDesignGroupRouter';
import oinvshareRouter from '@/router/modules/oinvshareRouter';

Vue.use(VueRouter);

/**
 * 重写路由的push方法
 * 解决NavigationDuplicated问题
 */
const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => error);
};

const routes = [
  {
    path: '/',
    redirect: '/dashboard'
  },
  {
    path: '/',
    component: () => import('@/components/common/Home.vue'),
    children: [
      {
        path: '/dashboard',
        component: () => import('@/views/Dashboard.vue'),
        meta: { title: '系统首页' }
      },
      cpttRouter.list,
      cpttRouter.add,
      cpttRouter.edit,
      deptRouter.list,
      deptRouter.add,
      deptRouter.edit,
      stffRouter.list,
      stffRouter.add,
      stffRouter.edit,
      stffRouter.push,
      roleRouter.list,
      roleRouter.edit,
      permRouter.list,
      userRouter.list,
      userRouter.add,
      userRouter.edit,
      apprRouter.list,
      apprRouter.edit,
      apprRouter.toApproved,
      apprRouter.beApproved,
      apprRouter.toApprovalRecord,
      persRouter.list,
      leavRouter.list,
      leavRouter.add,
      leavRouter.edit,
      dpvtRouter.list,
      optnRouter.list,
      optnRouter.edit,
      dequRouter.list,
      dequRouter.mpqStatistics,
      dequRouter.add,
      dequRouter.edit,
      custRouter.list,
      custRouter.add,
      custRouter.edit,
      desiRouter.list,
      desiRouter.add,
      desiRouter.edit,
      desiRouter.DesignStatisticalDraft,
      desiRouter.StatisticsOfCompletedTransactions,
      requRouter.list,
      requRouter.add,
      requRouter.edit,
      requRouter.push,
      requRouter.push1,
      requRouter.push9007,
      requRouter.push9008,
      requRouter.requBackDetail,
      suppRouter.list,
      suppRouter.add,
      suppRouter.edit,
      fequRouter.list,
      fequRouter.edit,
      fequRouter.makeSampleControlTable,
      fequRouter.push,
      fequRouter.push9009,
      qtsoRouter.list,
      qtsoRouter.add,
      qtsoRouter.edit,
      qutbRouter.list,
      qutbRouter.edit,
      settRouter.list,
      qtucRouter.list,
      qtucRouter.edit,
      instRouter.list,
      instRouter.add,
      instRouter.edit,
      mtrbRouter.list,
      mtrbRouter.add,
      mtrbRouter.edit,
      rqutRouter.list,
      rqutRouter.add,
      rqutRouter.edit,
      prodRouter.list,
      prodRouter.add,
      prodRouter.edit,
      sconRouter.list,
      sconRouter.newWeeklyPOReport,
      sconRouter.barkOrders,
      sconRouter.categorySalesStatistics,
      sconRouter.add,
      sconRouter.edit,
      sconRouter.scon_invpush_list_9006,
      sconOrderRouter.list,
      sconOrderRouter.add,
      sconOrderRouter.edit,
      clauRouter.list,
      clauRouter.add,
      clauRouter.edit,
      PodrRouter.list,
      PodrRouter.edit,
      PodrRouter.add,
      ModrRouter.list,
      ModrRouter.edit,
      ModrRouter.add,
      spinRouter.list,
      tranRouter.list,
      tranRouter.edit,
      tranRouter.add,
      deliRouter.list,
      deliRouter.edit,
      packRouter.list,
      packRouter.edit,
      pinvRouter.list,
      pinvRouter.edit,
      pinvRouter.add,
      puinRouter.list,
      mbinRouter.list,
      podcRouter.list,
      podcRouter.edit,
      podcRouter.add,
      modcRouter.list,
      modcRouter.edit,
      modcRouter.add,
      fpayRouter.list,
      fpayRouter.edit,
      fpayRouter.add,
      tinvRouter.list,
      tinvRouter.edit,
      tinvRouter.add,
      ppayRouter.list,
      ppayRouter.edit,
      segiRouter.list,
      segiRouter.edit,
      segiRouter.add,
      hegiRouter.list,
      hegiRouter.edit,
      hegiRouter.add,
      sginRouter.list,
      hginRouter.list,
      sollRouter.list,
      sollRouter.edit,
      hollRouter.list,
      hollRouter.edit,
      statRouter.list,
      statRouter.edit,
      statRouter.add,
      oinvRouter.list,
      oinvRouter.edit,
      oinvRouter.add,
      oinvshareRouter.list,
      oinvshareRouter.edit,
      reimRouter.list,
      reimRouter.edit,
      reimRouter.add,
      apayRouter.list,
      apayRouter.edit,
      apayRouter.add,
      adsoRouter.list,
      adsoRouter.edit,
      adsoRouter.add,
      stisRouter.list,
      stisRouter.listO,
      stisRouter.listT,
      stisRouter.listF,
      stisRouter.listS,
      stisRouter.listQ,
      stisRouter.listR,
      suctRouter.list,
      suctRouter.edit,
      suctRouter.add,
      catpRouter.list,
      exorRouter.list,
      usquRouter.list,
      usquRouter.add,
      usquRouter.edit,
      fcssRouter.list,
      actualRouter.list,
      pinvchangeRouter.list,
      pinvchangeRouter.edit,
      inquiryRouter.list,
      inquiryRouter.add,
      inquiryRouter.edit,
      inquiryRouter.push,
      consultRouter.list,
      consultRouter.edit,
      priceConsultRouter.list,
      priceConsultRouter.edit,
      informRouter.list,
      informRouter.edit,
      contractRouter.list,
      contractRouter.edit,
      mouldRouter.list,
      requisitionRouter.list,
      requisitionRouter.edit,
      requisitionRouter.idfam,
      domeSconRequisitionRouter.list,
      domeSconRequisitionRouter.edit,
      macoRouter.list,
      macoRouter.edit,
      macoRouter.add,
      quotationRouter.list,
      quotationRouter.edit,
      quotationRouter.add,
      fequSampleRouter.list,
      inventorysRouter.list,
      prodControlRouter.list,
      inspectionRouter.list,
      qcInspectionRouter.list,
      inspectionMtrbRouter.list,
      applicationRouter.list,
      applicationRouter.edit,
      applicationRouter.add,
      applicationMtrbRouter.list,
      applicationMtrbRouter.edit,
      applicationMtrbRouter.add,
      deliProdRouter.list,
      otherStatisticsRouter.fppcStatistics,
      otherStatisticsRouter.mpcStatistics,
      otherStatisticsRouter.scaStatistics,
      otherStatisticsRouter.ccpStatistics,
      otherStatisticsRouter.fscapmStatistics,
      otherStatisticsRouter.msaStatistics,
      otherStatisticsRouter.sdStatistics,
      otherStatisticsRouter.msdsStatistics,
      otherStatisticsRouter.pomodrDetailStatistics,
      otherStatisticsRouter.pomodrSumStatistics,
      otherStatisticsRouter.pomodrByCustStatistics,
      otherStatisticsRouter.suppMonthlyStatistics,
      DTdeliRouter.list,
      DTdeliRouter.edit,
      InveRouter.list,
      PushRouter.push9010,
      ProductDesignGroupRouter.list,
      ProductDesignGroupRouter.add,
      ProductDesignGroupRouter.edit
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue'),
    meta: { title: '登录' }
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('@/views/About.vue'),
    meta: { title: '表单设计' }
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;
