const otherStatisticsRouter = {
  fppcStatistics: {
    path: '/fppc_statistics',
    name: 'fppcStatistics',
    component: () => import('@/views/StatisticsManagement/FPPCStatistics/FPPCStatistics.vue'),
    meta: {
      title: '成品采购合同统计'
    }
  },
  mpcStatistics: {
    path: '/mpc_statistics',
    name: 'mpcStatistics',
    component: () => import('@/views/StatisticsManagement/MPCStatistics/MPCStatistics.vue'),
    meta: {
      title: '材料采购合同统计'
    }
  },
  scaStatistics: {
    path: '/sca_statistics',
    name: 'sacStatistics',
    component: () => import('@/views/StatisticsManagement/SCAStatistics/SCAStatistics.vue'),
    meta: {
      title: '销售合同金额统计'
    }
  },
  ccpStatistics: {
    path: '/ccp_statistics',
    name: 'ccpStatistics',
    component: () => import('@/views/StatisticsManagement/CCPStatistics/CCPStatistics.vue'),
    meta: {
      title: '客户分类打样统计'
    }
  },
  fscapmStatistics: {
    path: '/fscapm_statistics',
    name: 'fscapmStatistics',
    component: () => import('@/views/StatisticsManagement/FSCAPMStatistics/FSCAPMStatistics.vue'),
    meta: {
      title: '每月首次销售合同金额统计'
    }
  },
  msaStatistics: {
    path: '/msa_statistics',
    name: 'msaStatistics',
    component: () => import('@/views/StatisticsManagement/MSAStatistics/MASStatistics.vue'),
    meta: {
      title: '月度出运金额统计'
    }
  },
  sdStatistics: {
    path: '/sd_statistics',
    name: 'sdStatistics',
    component: () => import('@/views/StatisticsManagement/SDStatistics/SDStatistics.vue'),
    meta: {
      title: '出运明细统计'
    }
  },
  msdsStatistics: {
    path: '/msds_statistics',
    name: 'msdsStatistics',
    component: () => import('@/views/StatisticsManagement/MSDSStatistics/MSDSStatistics.vue'),
    meta: {
      title: '供应商月度数据汇总'
    }
  },
  pomodrDetailStatistics: {
    path: '/pomodrdetail_statistics',
    name: 'pomodrDetailStatistics',
    component: () => import('@/views/StatisticsManagement/PomodrDetailStatistics/PomodrDetailStatistics.vue'),
    meta: {
      title: '成品材料货号数量明细'
    }
  },
  pomodrSumStatistics: {
    path: '/pomodrsum_statistics',
    name: 'pomodrSumStatistics',
    component: () => import('@/views/StatisticsManagement/PomodrSumStatistics/PomodrSumStatistics.vue'),
    meta: {
      title: '成品材料货号数量汇总'
    }
  },
  pomodrByCustStatistics: {
    path: '/pomodrbycust_statistics',
    name: 'PomodrByCustStatistics',
    component: () => import('@/views/StatisticsManagement/PomodrByCustStatistics/PomodrByCustStatistics.vue'),
    meta: {
      title: '成品材料货号数量(按客户)'
    }
  },
  suppMonthlyStatistics: {
    path: '/suppmonthly_statistics',
    name: 'SuppMonthlyStatistics',
    component: () => import('@/views/StatisticsManagement/SuppMonthlyStatistics/SuppMonthlyStatistics.vue'),
    meta: {
      title: '供应商月度数据汇总表'
    }
  }
};
export default otherStatisticsRouter;
