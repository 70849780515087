/**
 * 数组工具类
 * @author HuangJun
 */

import { BigNumber } from 'bignumber.js';
import router from '@/router';
import UrlEncode from '@assets/js/UrlEncode';

/**
 * 将数组均匀分割成指定大小的子数组。
 *
 * @param {Array} array - 待分割的原始数组。
 * @param {number} size - 指定每个子数组的元素数量。
 * @returns {Array<Array>} - 由均匀分割得到的子数组组成的数组。
 *
 * @example
 * console.log(uniformArray([1, 2, 3, 4, 5, 6], 2));
 * // 输出：[[1, 2], [3, 4], [5, 6]]
 *
 * console.log(uniformArray([1, 2, 3, 4, 5], 3));
 * // 输出：[[1, 2, 3], [4, 5]]
 */
export const uniformArray = (array, size) => {
  const chunks = [];
  for (let index = 0; index < array.length; index += size) {
    chunks.push(array.slice(index, index + size));
  }
  return chunks;
};
/**
 * 从对象数组中提取指定属性的值，组成新数组。
 * 可选地，可以将结果数组转换为字符串形式返回。
 *
 * @param {Array} array - 原始对象数组，默认为空数组。
 * @param {String} column - 需要提取的属性名。
 * @param {Boolean} convertToString - 是否将结果数组转换为逗号分隔的字符串，默认为否。
 * @param joinStr
 * @returns {Array|String} - 提取的属性值组成的数组或字符串。
 */
export const getArrayIds = (array = [], column, convertToString = false, joinStr = ',') => {
  // 提取属性值到新数组
  const values = array.map(item => item[column]);

  // 根据参数决定是否转换为字符串
  return convertToString ? values.join(joinStr) : values;
};

/**
 * @description 删除表格行并加入标识
 * @param {string} rowId 行id(字段id)
 * @param {Array} array 表格数据
 * @param {Map} map map对象 用于存储有id的数据 并加入flag标识
 * @param {Array} selections 选中的表格数据
 * @return {Object} 表格数据, 已删除数据
 */
export const deleteTableRow = (rowId, array, map, selections) => {
  selections.forEach(item => {
    // 判断是否有对应id数据
    if (item[rowId] === 0 || item[rowId]) {
      // 增加标识
      // Vue.set(array[item.index], 'destroy_flag', 1);
      array[item.index].destroy_flag = 1;
      // map存储
      map.set(item.index, array[item.index]);
    } else {
      // 删除选中的无id数据 直接删除
      delete array[item.index];
    }
  });
  array = array.filter(x => x !== undefined && x.destroy_flag !== 1);
  return { tableData: array, map: map };
};
/**
 * 计算对象数组中指定属性的数值总和，支持指定小数位数、舍入模式，并可选择返回字符串格式。
 *
 * @param {Array<Object>} array - 待处理的包含数值属性的对象数组。
 * @param {String} column - 需要求和的属性名称。
 * @param {Number} [fixed=4] - 结果保留的小数位数，默认为4。
 * @param {Number|null} [roundingMode=null] - 舍入模式，遵循BigNumber的舍入规则，默认为null，即四舍五入。
 * @param {Boolean} [string=false] - 是否将结果转换为字符串返回，默认为false。
 *
 * @returns {Number|String} - 求和结果，根据`string`参数决定返回BigNumber实例或相应小数位的字符串表示。
 *
 * @example
 * const data = [{value: 1.23456}, {value: 2.34567}, {value: 3.45678}];
 * console.log(objectArrayReduce(data, 'value', 2)); // 输出 BigNumber 实例，数值为 6.99
 * console.log(objectArrayReduce(data, 'value', 2, BigNumber.ROUND_DOWN, true)); // 输出 "6.98"
 */
export const objectArrayReduce = (array, column, fixed = 4, roundingMode = null, string = false) => {
  if (!array || array.length === 0) {
    return string ? '0.' + '0'.repeat(fixed) : 0;
  }

  let initValue = new BigNumber(0);
  let sum = array.reduce((accumulator, currentItem) => {
    return accumulator.plus(new BigNumber(currentItem[column] || 0));
  }, initValue);

  return string ? sum.toString() : sum.toFixed(fixed, roundingMode !== null ? roundingMode : undefined);
};
export const objectArrayDeRepeat = array => [...new Set(array.map(JSON.stringify))].map(m => JSON.parse(m));
export const arrayDeRepeat = array => [...new Set(array)];
/**
 * 异步顺序地将一系列页面推向路由栈，并添加额外参数。
 * 对于给定的 ID 数组，该函数会逐一调用路由器的 push 方法，
 * 每次推入带有当前时间戳、编码后的权限及表单 ID 和额外参数作为查询参数的新页面。
 * 这用于按顺序导航至多个具有特定参数的相同页面实例，常应用于分步处理场景。
 *
 * @param {Array} ids - 需要导航到的每个页面对应的表单 ID 数组。
 * @param {string} name - 路由名称，指示要导航到的页面类型。
 * @param {string|number} permId - 当前会话或用户的权限 ID，用于控制页面访问权限。
 * @param {Object} extraParams - 额外的查询参数对象。
 *
 * @async
 */
export const pushPagesSequentially = async (ids, name, permId, extraParams = {}) => {
  for (let i = 0; i < ids.length; i++) {
    // 为每一次页面跳转生成一个新的时间戳，确保 URL 的独特性，避免缓存问题
    const timestamp = Date.now().toString();

    // 编码权限 ID 和表单 ID 为查询字符串，以便安全传递作为查询参数
    const encodedQuery = UrlEncode.encode(
      JSON.stringify({
        perm_id: permId,
        form_id: ids[i]
      })
    );

    // 合并额外参数
    const queryParams = {
      t: timestamp,
      key: encodedQuery,
      ...extraParams
    };

    // 使用 await 关键字确保按顺序执行页面跳转
    await router.push({
      name,
      query: queryParams
    });
  }
};
/**
 * 若数组非空，则将其转换为字符串并更新在表单对象中。
 * 若数组为空，确保表单对象中该键对应的值为一个空数组。
 *
 * @param {Object} form - 表单数据对象。
 * @param {string} arrayColumn - 要转换的数组字段的键名。
 */
export const arrayToString = (form, arrayColumn) => {
  if (Array.isArray(form[arrayColumn]) && form[arrayColumn].length > 0) {
    form[arrayColumn] = form[arrayColumn].join(',');
  } else if (!form.hasOwnProperty(arrayColumn)) {
    form[arrayColumn] = [];
  }
};
export const sortArrayByFirstArrayProp = (firstArray, secondArray) => {
  // 创建一个 Map 来存储第一个数组中的 prop 及其索引和 fixed 值
  const propIndexMap = new Map();
  firstArray.forEach((item, index) => {
    propIndexMap.set(item.prop, { index, fixed: item.fixed });
  });

  // 对第二个数组进行排序，并设置 fixed 值
  return secondArray.sort((a, b) => {
    // 获取索引值
    const indexA = propIndexMap.get(a.prop)?.index ?? Infinity;
    const indexB = propIndexMap.get(b.prop)?.index ?? Infinity;

    // 设置 fixed 值
    a.fixed = propIndexMap.get(a.prop)?.fixed ?? '';
    b.fixed = propIndexMap.get(b.prop)?.fixed ?? '';

    return indexA - indexB;
  });
};
