const pathRewrite = '/api';
let topic = {
  dataTopic: pathRewrite + '/data', // data公共类api
  imgeTopic: pathRewrite + '/imge', // 图片上传
  cpttTopic: pathRewrite + '/cptt', // 公司抬头信息管理
  docuTopic: pathRewrite + '/docu', // 相关文档
  deptTopic: pathRewrite + '/dept', // 部门信息管理
  stffTopic: pathRewrite + '/stff', // 员工信息管理
  roleTopic: pathRewrite + '/role', // 角色管理
  permTopic: pathRewrite + '/perm', // 权限管理
  userTopic: pathRewrite + '/user', // 用户管理
  apprTopic: pathRewrite + '/appr', // 审批管理
  leavTopic: pathRewrite + '/leav', // 请假管理
  dpvtTopic: pathRewrite + '/stff', // 部门假期
  optnTopic: pathRewrite + '/optn', // 数据中心
  custTopic: pathRewrite + '/cust', // 客户信息
  dequTopic: pathRewrite + '/dequ', // 委托设计单
  desiTopic: pathRewrite + '/desi', // 委托设计单
  requTopic: pathRewrite + '/requ', // 委托设计单
  suppTopic: pathRewrite + '/supp', //供应商信息
  fequTopic: pathRewrite + '/fequ', // 工厂打样单
  qtsoTopic: pathRewrite + '/qtso', //报价素材
  qutbTopic: pathRewrite + '/qutb', //二部报价明细
  settTopic: pathRewrite + '/sett', //参数信息
  qtucTopic: pathRewrite + '/qutc', //简易报价单
  qutcPdfTopic: pathRewrite + '/qutc/pdf/',
  openTopic: pathRewrite + '/open', //公共类
  instTopic: pathRewrite + '/inst', //机构信息
  mtrbTopic: pathRewrite + '/mtrb', //材料信息
  rqutTopic: pathRewrite + '/rqut', //询价单
  prodTopic: pathRewrite + '/prod', //成品信息
  sconTopic: pathRewrite + '/scon', //销售合同
  sconOrderTopic: pathRewrite + '/sconOrder', //销售合同
  clauTopic: pathRewrite + '/clau', //条款信息
  podrTopic: pathRewrite + '/podr', //成品采购合同
  modrTopic: pathRewrite + '/modr', //材料采购合同
  spinTopic: pathRewrite + '/spin', //出运清单
  tranTopic: pathRewrite + '/tran', //出运信息
  deliTopic: pathRewrite + '/deli', //进仓单
  packTopic: pathRewrite + '/pack', //拖箱单
  pinvTopic: pathRewrite + '/pinv', //采购发票
  pinvchangeTopic: pathRewrite + '/pinvchange', //采购发票
  openfcpTopic: pathRewrite + '/open/fcp/api', //FCP用的公开接口
  puinTopic: pathRewrite + '/puin', //成品申购需求
  mbinTopic: pathRewrite + '/mbin', //材料申购需求
  podcTopic: pathRewrite + '/podc', //成品采购变更单
  modcTopic: pathRewrite + '/modc', //材料采购变更单
  ppayTopic: pathRewrite + '/ppay', //采购付款单
  fpayTopic: pathRewrite + '/fpay', //费用付款单
  tinvTopic: pathRewrite + '/tinv', //转开发票
  segiTopic: pathRewrite + '/receipt/segi', //SH收款登记单
  hegiTopic: pathRewrite + '/receipt/hegi', //HK收款登记单
  sollTopic: pathRewrite + '/registration/soll', //SH转收款单
  hollTopic: pathRewrite + '/registration/holl', //HK转收款单
  statTopic: pathRewrite + '/stat', //结算单
  oinvTopic: pathRewrite + '/oinv', //其他转开发票
  oinvshareTopic: pathRewrite + '/oinvshare', //进口商品分摊明细
  reimTopic: pathRewrite + '/reim', //费用报销单
  apayTopic: pathRewrite + '/apay', //付款申请书
  adsoTopic: pathRewrite + '/adso', //SH预收款单
  ppinTopic: pathRewrite + '/ppin', //应付账款查询
  soinTopic: pathRewrite + '/soin', //SH应收账款查询
  hoinTopic: pathRewrite + '/hoin', //HK应收账款查询
  cusaTopic: pathRewrite + '/cusa', //客户销售统计
  rpinTopic: pathRewrite + '/rpin', //常5报表
  ouinTopic: pathRewrite + '/ouin', //委外加工需求
  outsTopic: pathRewrite + '/outs', //委外加工合同
  catpTopic: pathRewrite + '/catp', //猫草列表
  exorTopic: pathRewrite + '/exor', //外销成品统计
  usquTopic: pathRewrite + '/usqu', //美金报价单
  actualTopic: pathRewrite + '/actual', // 采购实际发票
  inquiryTopic: pathRewrite + '/inquiry', // 采购实际发票
  consultTopic: pathRewrite + '/consult', //订单咨询单
  priceConsultTopic: pathRewrite + '/pcst', // 价格咨询单
  informTopic: pathRewrite + '/inform', // 托收信息
  contractTopic: pathRewrite + '/contract', // 托收合同
  mouldTopic: pathRewrite + '/mould', // 模具信息
  requisitionTopic: pathRewrite + '/requisition', // 开票通知单
  domeSconRequisitionTopic: pathRewrite + '/domeScon', // 内贸销售开票通知单
  macoTopic: pathRewrite + '/maco', // 材料咨询单
  quotationTopic: pathRewrite + '/quotation', // 美金报价单(其他部门)
  fequSampleTopic: pathRewrite + '/fequSample', // 工厂打样明细
  inventorysTopic: pathRewrite + '/inventory', // 订单库存
  prodControlTopic: pathRewrite + '/prodcontrol', // 生成控制
  inspecationTopic: pathRewrite + '/inspecation', // 验货控制表
  inspectionTopic: pathRewrite + '/inspectionprod', // 验货清单
  applicationTopic: pathRewrite + '/inspecationprod', // 验货申请单
  deliProdTopic: pathRewrite + '/deliProd', // 进仓单汇总
  inspectionMtrbTopic: pathRewrite + '/inspmtrb', // 验货清单(材料)
  applicationMtrbTopic: pathRewrite + '/inspecationmtrb', // 验货申请单(材料)
  rpinmonthTopic: pathRewrite + '/rpinmonth', // 05月度
  DTdeliTopic: pathRewrite + '/DTdeli', // 内贸进仓单
  inveTopic: pathRewrite + '/inve', // 发票验证
  push: pathRewrite + '/push', // 通用push前缀
  productDesignGroupTopic: pathRewrite + '/designTeam', // 产品设计组
  pomodrstatistis: pathRewrite + '/pomodrstatistis' // 成品材料货号统计
};
export default topic;
