import Vue from 'vue';
import UmyUi from 'umy-ui';
import 'umy-ui/lib/theme-chalk/index.css'; // 引入样式
import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueCookies from 'vue-cookies';
import helper from './assets/js/helper.js';
import comApi from './assets/js/comApi.js';
import 'default-passive-events';
import AES from './assets/js/crtp.js';
import './plugins/element.js';
import './assets/css/global.scss';
import UrlEncode from './assets/js/UrlEncode.js';
import VueSticky from 'vue-sticky';
import Print from 'vue-print-nb';
import websocket from 'vue-native-websocket';
import NgForm from 'ng-form-element';
import 'ng-form-element/lib/ng-form-element.css';
import { leaveAndClickDirective } from '@assets/js/customDirectives';

export const EventBus = new Vue();
Vue.use(NgForm);
Vue.use(Print);
Vue.use(UmyUi);
Vue.prototype.$UrlEncode = UrlEncode;
Vue.prototype.$EventBus = new Vue();
Vue.directive('sticky', VueSticky);
Vue.directive('leave-and-click', leaveAndClickDirective);
Vue.use(VueAxios, axios);
Vue.use(VueCookies);
Vue.config.productionTip = false;
Vue.prototype.helper = helper;
Vue.prototype.comApi = comApi;
Vue.prototype.AES = AES;
Vue.prototype.jump = (p, q) => {
  router.push({ path: p, query: q }).catch(err => err);
};
Vue.use(websocket, '', {
  connectManually: true, // 手动连接
  format: 'json', // json格式
  reconnection: true, // 是否自动重连
  reconnectionAttempts: 5, // 自动重连次数
  reconnectionDelay: 2000 // 重连间隔时间
});
// 加密方式跳转
Vue.prototype.enRouter = (path, query) => {
  router.push({ path: path, query: { key: UrlEncode.encode(JSON.stringify({ query })) } });
};
// let needOperationPageNameList = ['/fppc_statistics'];
router.beforeEach((to, from, next) => {
  // if (needOperationPageNameList.includes(from.path)) {
  //   console.log(from);
  //   from.matched[1]?.components.default.methods.saveRow({}, 1);
  // }
  if (Vue.prototype.$cookies.get('userInfo')) {
    next();
  } else {
    if (to.fullPath === '/login') {
      next();
    } else {
      next({ path: '/login' });
    }
  }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
